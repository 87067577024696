import { useUser } from "@/components/hooks/useUser.js";
import { GetTemplateRes } from "@/types/templates";
import { Suspense, lazy, useState } from "react";
import useSWR from "swr";

import { DocProgress } from "./doc/DocProgress.js";

const DraftWizard = lazy(
  async () => await import("@/components/doc/DraftWizard.tsx")
);

export const DraftWizardExtended = () => {
  const { user } = useUser();
  const [templateId, setTemplateId] = useState("grant");

  const { data, isLoading } = useSWR<GetTemplateRes>(
    `/p/templates/${templateId}`,
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <>
      <DocProgress progress={0} />

      <Suspense fallback={null}>
        <DraftWizard
          disabled={isLoading}
          examples={data?.template?.examples ?? []}
          hideExamples={user?.hasCreatedDoc}
          onTemplateChange={setTemplateId}
          placeholder={data?.template?.placeholderText ?? ""}
          templateId={data?.template?.id}
        />
      </Suspense>
    </>
  );
};
