import { ExtendedButton } from '@/components/ExtendedButton'
import { Button, ButtonProps } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { postFetch } from '@/lib/fetchers'
import { type AuthReq, type AuthResp, type TokenAuthReq } from '@/types/auth'
import { type UpdateMeReq } from '@/types/users'
import { ArrowDown, ArrowRight, MailOpen } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'

export type DoneFn = (teamId?: string) => void

const CheckYourEmail = () => (
  <div className="flex flex-col justify-center gap-6">
    <div className="text-xl text-left">
      Please check your email inbox and click the link in the email to sign in.
    </div>

    <div className="p-4 border rounded flex items-center text-xl">
      <MailOpen className="mr-2" size="25" />We've sent you an email
    </div>
  </div>
)

const ConfirmProductEmail = ({ onDone, teamId }: { onDone?: DoneFn, teamId?: string }) => {
  const { trigger: updateMe } =
    useSWRMutation('/a/me', postFetch<UpdateMeReq, unknown>)

  return (
    <div className="flex flex-col justify-center gap-6">
      <div>
        <div className="text-lg font-bold mb-3">Welcome, You're Signed In</div>
        <p>
          <mark className="bg-amber-100">Check the box below</mark> to confirm that you want to receive news, product updates and discount offers from us.
        </p>
      </div>
      <div className="flex items-center space-x-3 tem-center p-4 border rounded">
        <Checkbox className="h-8 w-8 border-stone-800"
          id="sendEmailUpdates"
          onCheckedChange={
            async (product: boolean) => await updateMe({
              sendEmails: { product }
            })
          }
          required={true}
        />
        <label
          className="text-sm"
          htmlFor="sendEmailUpdates"
        >
          Yes, I want to receive emails about new features, products, etc.
        </label>
      </div>
      <Button
        className="text-xl font-semibold bg-indigo-500 hover:bg-indigo-600"
        onClick={() => { onDone?.(teamId) }}
        size="xl"
      >Start Creating</Button>
    </div >
  )
}

const SignWithToken = ({ onDone, onError, token }: { onDone: DoneFn, onError: (arg0: string) => void, token: string }) => {
  const [state, setState] = useState<AuthResp | undefined>()

  const { mutate } = useSWRConfig()

  const { trigger: signWithToken } =
    useSWRMutation('/p/auth/token', postFetch<TokenAuthReq, AuthResp>)

  const onTokenSubmit = async () => {
    const { status, teamId } = await signWithToken({ token })

    if (status === 'invalidToken') {
      onError('You clicked an expired link. Please sign in again with your email')
      return
    }

    if (status === 'ok') {
      await mutate(() => true, undefined)
      onDone(state?.teamId)
      return;
    }

    if (status === "confirmEmailSending") {
      await mutate(() => true, undefined)
    }

    setState({ status, teamId })
  }

  if (state?.status === 'confirmEmailSending') {
    return <ConfirmProductEmail onDone={onDone} teamId={state.teamId} />
  }

  return (
    <div className="flex flex-col justify-center gap-6">
      <div className="text-xl text-left">
        You're Signed In, Click the button below to continue <ArrowDown className="inline-block" />
      </div>

      <Button
        className="text-xl font-semibold bg-indigo-500 hover:bg-indigo-600"
        onClick={onTokenSubmit}
        size="xl">
        Start Creating
      </Button>
    </div>
  )
}

export const SignInForm = ({ buttonSize, error, hideMessage, onDone }: { buttonSize?: ButtonProps["size"], error?: string, hideMessage?: boolean, onDone?: () => void }) => {
  const [status, setStatus] = useState<string | undefined>()

  const form = useForm<AuthReq>({ defaultValues: { email: '' } })

  const { mutate } = useSWRConfig()

  const { isMutating, trigger: signinWithEmail } =
    useSWRMutation('/p/auth/email', postFetch<AuthReq, AuthResp>)

  const onSubmit = form.handleSubmit(async (v) => {
    const { status } = await signinWithEmail(v)

    if (status === 'ok') {
      await mutate(() => true, undefined)
      onDone?.()
      return
    }

    if (status === "confirmEmailSending") {
      await mutate(() => true, undefined)
    }

    setStatus(status)
  })

  if (status === 'checkEmail') {
    return <CheckYourEmail />
  }

  if (status === 'confirmEmailSending') {
    return <ConfirmProductEmail onDone={onDone} />
  }

  return (
    <>
      {!hideMessage && <div className="text-xl text-left">
        Enter your email below and click the button to continue <ArrowDown className="inline-block" />
      </div>}
      <Form {...form}
      >
        <form
          className="flex flex-col justify-center gap-6"
          onSubmit={onSubmit}>

          {error && <div className="text-red-500">{error}</div>}
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem >
                <FormControl>
                  <Input
                    className="bg-stone-100 text-xl text-black border-0"
                    placeholder="Enter your email"
                    required={true}
                    type="email"
                    {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <ExtendedButton
            className="text-xl font-semibold bg-lime-400 hover:bg-lime-500 disabled:bg-lime-500 text-black"
            disabled={isMutating}
            isLoading={isMutating}
            label={<>Click to Start <ArrowRight className="ml-2" /></>}
            size={buttonSize ?? "xl"}
            type="submit"
          />
        </form>
      </Form>
    </>

  )
}

export const SignIn = ({ onDone, token }: { onDone: () => void, token?: string }) => {
  const [error, setError] = useState<string | undefined>()

  if (error) {
    return <SignInForm error={error} onDone={onDone} />
  }

  if (token) {
    return <SignWithToken onDone={onDone} onError={setError} token={token} />
  } else {
    return <SignInForm onDone={onDone} />
  }
}
