import { cn } from "@/lib/utils";
import React, { useEffect, useState } from "react";

interface Marker {
  label: string;
  position: number;
}

interface ProgressBarProps {
  markers: Marker[];
  progress: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  markers,
  progress,
}) => {
  const [activeMarker, setActiveMarker] = useState<null | number>(null);

  useEffect(() => {
    if (activeMarker === null) return;
    const timer = setTimeout(() => setActiveMarker(null), 3000);
    return () => clearTimeout(timer);
  }, [activeMarker]);

  return (
    <div className="px-4 py-2">
      <div className="relative w-full bg-gray-200 rounded-full">
        {/* Progress Bar */}
        <div
          className="bg-green-500 h-3 rounded-full"
          style={{ width: `${progress}%` }}
        ></div>

        {/* Markers */}
        {markers.map((marker, index) => {
          const isReached = progress >= marker.position;
          const isActive = activeMarker === index;

          return (
            <div
              className="absolute flex flex-col items-center"
              key={index}
              onClick={() => setActiveMarker(isActive ? null : index)}
              style={{
                left: `${marker.position}%`,
                top: "-6px",
                transform: "translateX(-50%)",
              }}
            >
              {/* Marker Circle */}
              <div
                className={cn(
                  `w-6 h-6 rounded-full flex items-center justify-center text-sm font-semibold`,
                  isReached
                    ? "text-white bg-green-600"
                    : "text-gray-400 bg-gray-300"
                )}
              >
                {index + 1}
              </div>
              {/* Marker Text */}
              {isActive && (
                <span className="text-xs md:text-sm mt-1 z-50 border bg-white p-1">
                  {marker.label}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
