import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer'
import { type GetTaskRes } from '@/types/tasks'
import { type GetTemplateRes } from '@/types/templates'
import { CheckCircle2 } from 'lucide-react'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { useLocation } from 'wouter'

interface TaskProgressProps {
  description?: string
  fields?: GetTemplateRes['fields']
  redirectTo: string
  taskId?: string
  title: string
}

const WorkDoneList = ({ fields = [], percentage = 0 }: { fields?: GetTemplateRes['fields'], percentage?: number }) => {
  if (!fields || fields.length === 0) {
    return null
  }

  const list = getDoneWork(fields, percentage)

  return (
    <div className="flex flex-col gap-2">
      {list?.map((item, index) => (
        <div className="flex items-center gap-1 text-sm" key={index}>
          <CheckCircle2 className="fill-pink-500 stroke-white" size="20" />
          <div>{item.newTitle ?? item.title}</div>
        </div>
      ))}
    </div>
  )
}

export const TaskProgressDialog = ({ description, fields, redirectTo, taskId, title }: TaskProgressProps) => {
  const [open, setOpen] = useState(false)

  const { data: task } = useSWR<GetTaskRes>(open ? `/a/tasks/${taskId}` : null, {
    refreshInterval: 2000
  })
  const [, navigate] = useLocation()

  useEffect(() => {
    if (task?.status === 'done') {
      setOpen(false)
      navigate(redirectTo)
    }
  }, [task, navigate, redirectTo])

  useEffect(() => {
    if (taskId && taskId.length > 0) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [taskId])

  return (
    <Drawer open={open}>
      <DrawerContent>
        <div className="w-8/10 mx-auto pb-20 px-2 text-left">
          <DrawerHeader>
            <DrawerTitle>{title}</DrawerTitle>
            <DrawerDescription>
              {description}
            </DrawerDescription>
          </DrawerHeader>
          <div className="w-full space-y-4 px-4">
            <WorkDoneList
              fields={fields}
              percentage={task?.percentComplete}
            />
            <div className="text-3xl font-extrabold">
              {task?.percentComplete ?? 5}%
            </div>
            <div className='h-1.5 w-full bg-pink-100 overflow-hidden'>
              <div className='animate-progress w-full h-full bg-pink-500 origin-left-right'></div>
            </div>
          </div>
        </div>

      </DrawerContent>
    </Drawer>
  )
}

const getDoneWork = (array: GetTemplateRes['fields'], percentage: number) => {
  if (percentage < 0 || percentage > 100) {
    throw new Error('Percentage must be between 0 and 100.')
  }
  if (array.length === 0) {
    throw new Error('Array cannot be empty.')
  }

  // Calculate the index based on the percentage
  const index = Math.min(
    Math.floor((percentage / 100) * array.length),
    array.length - 1
  )

  // Return the slice of the array from 0 to the calculated index (inclusive)
  return array.slice(0, index + 1)
}
