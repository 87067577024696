import { Alert, AlertDescription, AlertTitle } from "./ui/alert.js";

interface AlertProps {
  message: string;
  title: string;
  variant: "default" | "destructive" | null | undefined;
}

export const AlertNote = ({ message, title, variant }: AlertProps) => {
  return (
    <Alert variant={variant}>
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
};
