import { TeamsList } from '@/components/TeamsList.js'
import { GetDocRes } from "@/types/docs.js"
import { ListTeamRes } from "@/types/teams"
import { GetTemplateRes } from "@/types/templates"

import { TemplateTags } from './TemplateTags.js'

interface DocFormHeaderProps {
  name?: string
  parentId?: string
  teams?: ListTeamRes
  template?: GetDocRes | GetTemplateRes
}

export const DocFormHeader = ({
  name,
  parentId,
  teams,
  template
}: DocFormHeaderProps) => {
  return (
    <div className="space-y-2">
      {name && <div className="text-4xl px-4">{name}</div>}

      <div className="flex flex-wrap items-center gap-5 px-4">
        {parentId && teams && teams.length > 0 && <TeamsList
          suffixPath={`grants/${parentId}`} teams={teams} />}

        {template && <>
          <TemplateTags doc={template} />
          {('isExpired' in template && template.isExpired) && <div className="text-red-500 text-sm">(Deadline passed)</div>}
        </>}
      </div>
    </div>
  )
}