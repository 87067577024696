import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/ui/tooltip'

export const Hint = ({ children, text }: {
  children: React.ReactNode
  text: string
}) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        {children}
      </TooltipTrigger>
      <TooltipContent className="w-[300px] border border-indigo-700">
        <p>{text}</p>
      </TooltipContent>
    </Tooltip>
  )
}
