import { Button } from "@/components/ui/button"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { CircleHelp, Quote } from 'lucide-react'

export const HelpButton = ({ link, linkText, text }: { link?: string, linkText?: string, text: string }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="border-black" variant="outline"><CircleHelp className="mr-1" size={18} />Help</Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="text-sm text-green-600 font-medium border-2 border-green-600 rounded-lg">
        <Quote className="hidden md:block mr-2" size="45" />
        <div>{text} </div>
        {link && <div className="mt-4 bg-gray-100 hover:bg-gray-200 p-3 rounded-lg text-black text-sm">
          <a href={link} rel="noreferrer" target="_blank">Guide: <span className="underline">{linkText}</span></a>
        </div>}
        <div className="mt-4 bg-gray-100 hover:bg-gray-200 p-3 rounded-lg text-black text-sm">
          <a href="mailto:deepa@grantorb.com">Feel free to email us: <span className="underline">deepa@grantorb.com</span></a>
        </div>
      </PopoverContent>
    </Popover>
  )
}
