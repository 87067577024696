import { cn } from "@/lib/utils"
import { GetDocRes } from "@/types/docs"

import { UserInput } from './Section.js'

export const ValidationReport = ({ doc, showCriteria }: { doc: GetDocRes, showCriteria?: boolean }) => {
  return (
    <div className="w-full space-y-3 py-3">
      {showCriteria && doc.criteria && (<UserInput
        format={true}
        showTitle={true}
        text={doc.criteria}
        title="Validation Criteria"
      />)}
      {doc.review?.map((item, i) => (
        <div className="flex items-center gap-2 text-md text-gray-700 px-3" key={i}>
          <div
            className={
              cn("h-5 w-10", setRatingColor(item.rating))}>
          </div>
          {item.comment}
        </div>
      ))}
    </div>
  )
}

const setRatingColor = (rating?: number) => {
  switch (rating) {
    case 1:
      return 'bg-red-400'
    case 2:
      return 'bg-green-200'
    case 3:
      return 'bg-green-300'
    case 4:
      return 'bg-green-400'
    case 5:
      return 'bg-green-500'
    default:
      return 'bg-gray-100'
  }
}