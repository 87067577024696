import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

export const GAdsConversionTracker = () => {
  const url = new URL(document.location.toString())
  const qs = url.searchParams

  useEffect(() => {
    if (qs.has('checkout', 'success')) {
      const txnId = qs.get('txnId')

      TagManager.dataLayer({
        dataLayer: {
          event: 'gad_conversion',
          gad_conversion_id: '16474887007',
          gad_conversion_label: 'f8gTCIyC7Z4ZEN-m6689',
          gad_conversion_value: 30,
          gad_tnx_id: txnId
        }
      })

      qs.delete('checkout')
      qs.delete('txnId')

      window.history.pushState({}, '', url)
    }
  }, [])

  return null
}
