import { ArrowRight } from 'lucide-react'
import { Link } from 'wouter'

import { Button } from './ui/button.js'

export const TeamBanner = () => {
  return (
    <div className="space-y-3">
      <div className="text-md">
        <span className="font-bold">Grant Intelligence</span> a new AI powered search. Create a team so we know more about your organization to match it with the right grants.
      </div>
      <Button asChild className="flex items-center gap-2">
        <Link to="/team">
          Setup Your Team
          <ArrowRight size="20" />
        </Link>
      </Button>
    </div>
  )
}
