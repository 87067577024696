import { ShowMore } from "@/components/ShowMore.js";
import { TextInput } from "@/components/TextInput.js";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form.js";
import { type GetTemplateRes } from "@/types/templates.js";
import { type Control } from "react-hook-form";

import { type DocFormType } from "./doc.js";

interface DocFormItemProps {
  control: Control<DocFormType>;
  disabled?: boolean;
  item: GetTemplateRes["fields"][0];
}

export const DocFormItem = ({ control, disabled, item }: DocFormItemProps) => (
  <FormField
    control={control}
    name={`draft.${item.id}.userText`}
    render={({ field }) => (
      <FormItem>
        <div>
          <div className="text-2xl">
            <div className="flex items-center gap-2">
              {item.newTitle ?? item.title}
              {item.required ? (
                <span className="text-red-500 text-xs">(Required)</span>
              ) : (
                <span className="text-stone-500 text-xs">(Optional)</span>
              )}
            </div>
          </div>
          <div className="text-stone-500">
            {item.newDescription ?? item.description}
          </div>
        </div>
        <FormControl>
          <TextInput
            {...field}
            className="bg-gray-100"
            disabled={disabled}
            maxLength={item.maxLength ?? 4000}
            placeholder="Enter brief details for this section. Use the example as a guide."
          />
        </FormControl>
        <FormMessage />
        {item.example && <ShowMore label="Example" text={item.example} />}
      </FormItem>
    )}
  />
);

interface DocFormItemAutoProps {
  item: GetTemplateRes["fields"][0];
}

export const DocFormItemAuto = ({ item }: DocFormItemAutoProps) => (
  <>
    <div className="text-2xl flex items-center gap-2">
      {item.newTitle ?? item.title}
      <span className="text-red-500 text-xs">(Auto Generated)</span>
    </div>
    <div className="text-stone-500 mt-1">
      {item.newDescription ?? item.description}
    </div>
    {/* <div className="text-stone-500 mt-1">This section will be automatically created you do not need to provide any information</div> */}
  </>
);
